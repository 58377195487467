import styled from "styled-components";

const Header = ({ headerRef, logoUrl }) => {
  return (
    <Container ref={headerRef}>
      <div className="inner">
        {logoUrl && (
          <img className="logo" src={logoUrl} alt="Webcast Squared Logo" />
        )}
      </div>
    </Container>
  );
};

export default Header;

const Container = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  z-index: 9;
  border-bottom: 2px solid #fff;
  display: flex;
  @media (max-width: 800px) {
    justify-content: center;
  }

  .logo {
    margin: 1em 6em;
    @media (max-width: 800px) {
      margin: 1em;
    }
    max-width: 200px;
    object-fit: contain;
  }
`;
