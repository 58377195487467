import { motion } from "framer-motion";
import styled from "styled-components";

const dotsVars = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      // repeat: Infinity,
      // repeatDelay: 1,
      staggerChildren: 0.1,
    },
  },
};

const dotVars = {
  hidden: { y: 15, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Loading = () => {
  return (
    <Container>
      <img
        className="logo"
        src="https://webcastsquared.com/wp-content/uploads/2021/11/logo-large-300x58.png"
        alt="Webcast Squared Logo"
      />
      <motion.div
        className="dots"
        variants={dotsVars}
        initial="hidden"
        animate="visible"
      >
        <motion.span
          className="dot"
          variants={dotVars}
          transition={{ repeat: Infinity, repeatDelay: 1 }}
        ></motion.span>
        <motion.span
          className="dot"
          variants={dotVars}
          transition={{ repeat: Infinity, repeatDelay: 1 }}
        ></motion.span>
        <motion.span
          className="dot"
          variants={dotVars}
          transition={{ repeat: Infinity, repeatDelay: 1 }}
        ></motion.span>
      </motion.div>
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dots {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  .dot {
    display: inline-block;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    border: 5px solid #3f83db;
    border-radius: 100%;
    background: #ea4f4f;
    margin: 0.5em;
  }
`;
