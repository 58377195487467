import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FeaturedVideo from "./FeaturedVideo";
import Header from "./Header";
import InfoBoxes from "./InfoBoxes";
import Loading from "./styles/Loading";
import TopBanner from "./TopBanner";
import TeamMembers from "./TeamMembers";
import Contact from "./Contact";
import { AnimatePresence, motion } from "framer-motion";
import ContactSection from "./ContactSection";

function App() {
  const headerRef = useRef();
  const [state, setState] = useState({ loading: true });
  const [showContactSlideOut, setShowContactSlideOut] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  useEffect(() => {
    fetch(`https://webcastsquared.com/wp-json/wc2/v1/home`)
      // fetch(`${window.location.origin}/wp-json/wc2/v1/home`)
      .then((blob) => blob.json())
      .then((json) => {
        console.log(json);
        json[0].loading = false;
        setState(json[0]);
      });
  }, []);

  useEffect(() => {
    setOffsetTop(headerRef.current && headerRef.current.clientHeight);
  }, [headerRef.current]);
  return (
    <>
      <Header headerRef={headerRef} logoUrl={state.site_logo} />
      {state.loading ? (
        <Loading />
      ) : (
        <Container>
          <TopBanner
            banner={state.banner}
            setShowContactSlideOut={setShowContactSlideOut}
          />
          <InfoBoxes infoboxes={state.info_boxes} />
          <FeaturedVideo video={state.demo} offsetTop={offsetTop} />
          <TeamMembers team={state.team} />
          <AnimatePresence>
            {showContactSlideOut && (
              <SlideOutWrapper
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 800, opacity: 0 }}
              >
                <Contact
                  isSlideOut={true}
                  setShowContactSlideOut={setShowContactSlideOut}
                />
              </SlideOutWrapper>
            )}
          </AnimatePresence>
          <ContactSection
            contactInfo={state.contact_info}
            setShowContactSlideOut={setShowContactSlideOut}
          />
        </Container>
      )}
    </>
  );
}

export default App;

const Container = styled.div``;

const SlideOutWrapper = styled(motion.div)`
  height: 100vh;
  width: 100%;
  max-width: 800px;
  position: fixed;
  right: 0;
  top: 0;
  background: linear-gradient(
    45deg,
    rgba(234, 79, 79, 0.7) 20%,
    rgba(63, 131, 219, 0.7) 80%
  );
  z-index: 999;
  backdrop-filter: blur(8px);

  h2 {
    text-align: center;
  }
`;
